export const FETCH_ALL_USER_TXNS = `query ($pageSize:Int,$pageNumber:Int,$status:String) {
  list_all_transactions(page_size:$pageSize,page_number:$pageNumber,status:$status){
   message
   data {
     _id
     txn_id
     txn_signature
     type
     amount
     status
     created_at
     chain_network
     currency
     from_user_wid
     to_user_wid
     to_uid {
       _id
       name
       email
     }
     from_uid {
       _id
       name
       email
     }
   }
   hasMore
 }
 }
 `;

export const FETCH_USER_PROFILE = `{
  user_profile {
    message
    data {
      _id
      name
      email
      img
      wallets {
        _id
        wallet_address
        balance
        network
      }
    }
  }
}
`;

export const TRANSFER_TO_EMAIL = `mutation ($email: String!, $network: String, $amount: Float!) {
  send_crypto_to_email(email: $email, network: $network, amount: $amount) {
    message
  }
}
`;

export const TRANSFER_TO_WALLET = `mutation ($walletAddress: String!, $network: String, $amount: Float!) {
  send_crypto_to_wallet(wallet_address: $walletAddress, network: $network, amount: $amount) {
    message
  }
}
`;

export const FETCH_WALLET_BALANCE = `query ($network: String!) {
  list_wallet_balance(network: $network) {
    message
    data {
      wallet_address
      balance
      network
    }
  }
}
`;

export const SHARE_QR_LINK = `query ($email: String!) {
  share_qr_link(email: $email) {
    message
  }
}
`;

export const FETCH_USER_DETAILS = `query ($id: String!) {
  list_user_details(_id: $id) {
    message
    data {
      _id
      name
      email
      img
      wallets {
        wallet_address
        network
      }
    }
  }
}
`;
