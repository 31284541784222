import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FilledInput, Select, MenuItem, InputAdornment, CircularProgress as Loader } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Email, Money } from "@material-ui/icons";

import { transferToEmail, transferToWallet } from "store/actions";

function Transfer({ type, handleModal }) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    const initialValues = () => {
        let data = {
            network: "solana",
            amount: 0.02,
        };

        type === "email" ? (data.email = "") : (data.walletAddress = "");
        return data;
    };

    const validationSchema = () => {
        let data = {
            amount: Yup.number().min(0.02),
        };
        type === "email"
            ? (data.email = Yup.string().email().required("Email address is required"))
            : (data.walletAddress = Yup.string().required("Wallet address is required"));
        return data;
    };

    return (
        <>
            <Formik
                initialValues={initialValues()}
                validationSchema={Yup.object().shape(validationSchema())}
                onSubmit={async (value) => {
                    if (type === "email") {
                        const response = await dispatch(transferToEmail(value));
                        if (response) {
                            handleModal();
                        }
                    } else {
                        const response = await dispatch(transferToWallet(value));
                        if (response) {
                            handleModal();
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        {type === "email" ? (
                            <>
                                <FilledInput
                                    autoComplete="off"
                                    type="email"
                                    fullWidth
                                    placeholder="Enter Email Address"
                                    name="email"
                                    defaultValue={values.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Email />
                                        </InputAdornment>
                                    }
                                    required={true}
                                    error={Boolean(touched.email && errors.email)}
                                    disabled={isLoading}
                                />
                                {Boolean(touched.email && errors.email) ? (
                                    <div
                                        style={{
                                            display: "block",
                                            marginLeft: "10px",
                                            color: "red",
                                            fontSize: 13,
                                        }}
                                    >
                                        {errors.email}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <br />
                            </>
                        ) : (
                            <>
                                <FilledInput
                                    autoComplete="off"
                                    type="text"
                                    fullWidth
                                    placeholder="Enter Wallet Address"
                                    name="walletAddress"
                                    defaultValue={values.walletAddress}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Money />
                                        </InputAdornment>
                                    }
                                    required={true}
                                    error={Boolean(touched.walletAddress && errors.walletAddress)}
                                    disabled={isLoading}
                                />
                                {Boolean(touched.walletAddress && errors.walletAddress) ? (
                                    <div
                                        style={{
                                            display: "block",
                                            marginLeft: "10px",
                                            color: "red",
                                            fontSize: 13,
                                        }}
                                    >
                                        {errors.walletAddress}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <br />
                            </>
                        )}

                        <Select
                            labelId="demo-simple-select-label"
                            name="network"
                            value={values.network}
                            label="Network"
                            onChange={handleChange}
                            style={{ width: "100%", marginBottom: "15px" }}
                            disabled={isLoading}
                        >
                            <MenuItem value={"solana"}>Solana (Solana chain)</MenuItem>
                            <MenuItem value={"ethereum"}>Ethereum (Ethereum chain)</MenuItem>
                            <MenuItem value={"avalanche"}>Avalanche (Ethereum chain)</MenuItem>
                            <MenuItem value={"polygon"}>Polygon (Ethereum chain)</MenuItem>
                            <MenuItem value={"usdc"}>USDC (Ethereum chain)</MenuItem>
                        </Select>

                        <FilledInput
                            autoComplete="off"
                            type="number"
                            fullWidth
                            placeholder="Enter Amount"
                            name="amount"
                            defaultValue={values.amount}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Money />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.amount && errors.amount)}
                            disabled={isLoading}
                        />
                        {Boolean(touched.amount && errors.amount) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.amount}
                            </div>
                        ) : (
                            ""
                        )}
                        <br />
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                            <Button id="signin-btn" color="primary" variant="contained" onClick={handleSubmit}>
                                {isLoading ? <Loader color="secondary" size={20} /> : "Transfer"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default Transfer;
