import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Modal } from "@material-ui/core";

import { authRoutes } from "routes.js";
import componentStyles from "assets/theme/layouts/auth.js";
import styled from "styled-components";
import Login from "views/auth";
import { toggleModal } from "store/actions";
import { isLoggedIn } from "utils";

const useStyles = makeStyles(componentStyles);

const Outer = styled.div`
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(#222831, #277a7a, #222831);
    font-family: "Jost", sans-serif;
`;

const Inner = styled.div`
    padding: 50px 30px 100px;
    min-height: 100vh;
    width: 100%;
    color: #cccccc;
    backdrop-filter: blur(55px);
    overflow-x: scroll;
`;

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

const Auth = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();

    const { loading, modal, isAuthenticated } = useSelector(({ loading, modal, auth: { token } }) => ({
        loading,
        modal,
        isAuthenticated: isLoggedIn(token),
    }));

    React.useEffect(() => {
        document.body.classList.add(classes.bgDefault);
        return () => {
            document.body.classList.remove(classes.bgDefault);
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (!isAuthenticated) {
            dispatch(toggleModal(true));
        }
        // mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth" && !isAuthenticated) {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return <Redirect to={"/app/profile"} key={key} />;
            }
        });
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Outer>
                <Inner>
                    <Switch>
                        {getRoutes(authRoutes)}
                        <Redirect from="*" to="/auth/home" />
                    </Switch>
                </Inner>
            </Outer>

            <Modal open={modal}>
                <Loginbox>
                    <Login />
                </Loginbox>
            </Modal>
        </>
    );
};

export default Auth;
