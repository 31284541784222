import styled from "styled-components";

export const EmptyBox = styled.div`
   width: 100%;
   text-align: center; 
   padding: 100px 0; 
   background-color: rgb(0, 0, 0, 0.5);
   border-radius: 5px;
   font-weight: bold;
`;

export const Title = styled.div`
    margin-top: 50px;
    text-align: center;
    i {
        font-size: 10px;
        padding: 0px 10px;
    }
`;