import React, { useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import Transfer from "./Transfer";
import SelectType from "./SelectType";

function TransferCrypto({ handleModal }) {
    const [txnType, setTxnType] = useState("");

    return (
        <>
            <Grid item>
                <Card style={{ borderRadius: "20px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                    <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                        <p>{!txnType ? "Select transaction type" : `Transfer to ${txnType}`}</p>
                    </div>
                    <CardContent style={{ padding: "30px 50px 20px" }}>
                        {!txnType ? (
                            <SelectType setTxnType={setTxnType} />
                        ) : (
                            <Transfer type={txnType} handleModal={handleModal} />
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default TransferCrypto;
