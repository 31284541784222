import React, { useState } from "react";
import Login from "./login";
import Register from "./register";

const Home = () => {
    const [isLogin, setIsLogin] = useState(true);

    const handlePage = () => {
        setIsLogin(!isLogin);
    };

    return <>{isLogin ? <Login handlePage={handlePage} /> : <Register handlePage={handlePage} />}</>;
};

export default Home;
