import React from "react";
import { Button, Grid } from "@material-ui/core";

function SelectType({ setTxnType }) {
    return (
        <>
            <Grid container justify="center">
                <Grid item>
                    <Button id="signin-btn" color="primary" variant="contained" onClick={() => setTxnType("email")}>
                        To Email Address
                    </Button>
                </Grid>
            </Grid>
            <br/>
            <Grid container justify="center">
                <Grid item>
                    <Button id="signin-btn" color="primary" variant="contained" onClick={() => setTxnType("wallet")}>
                        To Wallet Address
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default SelectType;
