import { Grain } from "@material-ui/icons";

import Home from "views/auth/home";
// import Homepage from "views/homepage";
import ProfilePage from "views/profile";
import QrHome from "views/qr";
import Transactions from "views/transactions";

var adminRoutes = [
    {
        path: "/profile",
        name: "Profile",
        icon: "fa-solid fa-user",
        iconColor: "Primary",
        component: ProfilePage,
        layout: "/app",
        visibility: true,
    },
    // {
    //     path: "/home",
    //     name: "home",
    //     icon: "fa-solid fa-house",
    //     iconColor: "Primary",
    //     component: Homepage,
    //     layout: "/app",
    //     visibility: true,
    // },
    {
        path: "/transactions",
        name: "Transactions",
        icon: "fa fa-history",
        iconColor: "Primary",
        component: Transactions,
        layout: "/app",
        visibility: true,
    },

    // {
    //     path: "/companies/:country?/:index",
    //     name: "Companies",
    //     icon: "fa fa-home",
    //     iconColor: "Primary",
    //     component: Companies,
    //     layout: "/app",
    //     visibility: false,
    // },
    // {
    //     path: "/price-history",
    //     name: "Stock historical data",
    //     icon: "fa fa-home",
    //     iconColor: "Primary",
    //     component: StockPriceHistory,
    //     layout: "/app",
    //     visibility: false,
    // },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/home",
        name: "Listings",
        icon: Grain,
        iconColor: "Primary",
        component: Home,
        layout: "/auth",
        visibility: true,
    },
];

var qrRoutes = [
    {
        path: "/:data",
        name: "Listings",
        icon: Grain,
        iconColor: "Primary",
        component: QrHome,
        layout: "/qr",
        visibility: true,
    },
];
export { adminRoutes, authRoutes, qrRoutes };
