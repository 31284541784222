export const LOGIN_WITH_OTP = `query ($email: String!, $otp: String!) {
    login_with_otp(email: $email, otp: $otp) {
      message
      token
    }
  }
  `;

export const SEND_OTP = `mutation ($email: String!) {
    send_otp(email: $email) {
      message
    }
  }
  `;

export const REGISTER_NEW_USER = `mutation ($email: String!, $name: String!) {
  register_new_user(email: $email, name: $name) {
    message
  }
}
`;

export const VERIFY_NEW_USER = `query ($email: String!, $otp: String!) {
  verify_new_account(email: $email, otp: $otp) {
    message
  }
}
`;
