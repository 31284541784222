import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ContainedButton } from "components/StyledComponents";
import "./styles.css";

function ExpandableListView({ data, pairs, showViewMoreButton = false, viewMoreButtonClick }) {
    const [expandBtn, setExpandBtn] = useState(1);

    const handleExpandButton = (e) => {
        if (e === expandBtn) {
            setExpandBtn(0);
        } else {
            setExpandBtn(e);
        }
    };

    const colorIndicator = (element, value) => {
        if (element === value) {
            return { color: "#6bc654" };
        } else {
            return { color: "red" };
        }
    };

    return (
        <>
            {data?.length ? (
                <>
                    <Grid container className="listings">
                        {data.map((e, i) => (
                            <Grid className="element" item xl={12} lg={12} xs={12} key={i}>
                                <div className="item" onClick={() => handleExpandButton(i + 1)}>
                                    <div className="name">{e.txn_id}</div>
                                    {expandBtn !== i + 1 ? (
                                        <div className="value">
                                            {e.cmp}
                                            <i className="fas fa-long-arrow-alt-right" />
                                            <b style={colorIndicator(e.signal, "BUY")}>{e.target}</b>
                                        </div>
                                    ) : null}
                                    <div className="dropdown-btn" title="expand">
                                        {expandBtn === i + 1 ? (
                                            <i
                                                className="fas fa-chevron-circle-down"
                                                onClick={() => handleExpandButton(i + 1)}
                                            />
                                        ) : (
                                            <i
                                                className="fas fa-chevron-circle-right"
                                                onClick={() => handleExpandButton(i + 1)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {expandBtn === i + 1 ? (
                                    <div className="expanded">
                                        {pairs.map((element, index) => (
                                            <div key={index}>
                                                <div style={{ fontWeight: 600 }}>
                                                    {" "}
                                                    {element.key} :{" "}
                                                    {element.render ? element.render(e) : e[element.value] ?? ""}{" "}
                                                </div>
                                                {/* {element.colorIndication ? (
                                                    <div
                                                        className="value"
                                                        style={colorIndicator(
                                                            e[element.colorIndication.value],
                                                            element.colorIndication.condition
                                                        )}
                                                    >
                                                        {e[element.value]}
                                                    </div>
                                                ) : (
                                                    <div className="value">
                                                        {element.render ? element.render(e) : e[element.value] ?? ""}{" "}
                                                    </div>
                                                )} */}
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </Grid>
                        ))}
                        {showViewMoreButton ? (
                            <div style={{ display: "flex", justifyContent: "center", padding: "1em", width: "100%" }}>
                                <ContainedButton
                                    style={{ padding: "6px 10px", fontSize: "small" }}
                                    onClick={viewMoreButtonClick}
                                >
                                    View more
                                </ContainedButton>
                            </div>
                        ) : null}
                    </Grid>
                </>
            ) : (
                <div className="empty-msg">
                    <h3>No data found</h3>
                </div>
            )}
        </>
    );
}

export default ExpandableListView;
