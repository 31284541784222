import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import { Grid, Button, Chip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import styled from "styled-components";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";

import { history, getBlockchainNetwork } from "utils";
import { unAuthorizedPost } from "services";
import { FETCH_USER_DETAILS } from "graphql";
import { toggleLoading } from "store/actions";

const Box = styled.div`
    min-width: 150px;
    padding: 20px;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 15px;
    text-align: center;
`;

const AddressBar = styled.div`
    width: 100%;
    padding: 10px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #8c8c8c;
    border-radius: 10px;

    @media only screen and (max-width: 600px) {
        font-size: 15px;
    }
`;

const QrBox = styled.div`
    min-width: 150px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    justify-content: center;
`;

const QrHome = () => {
    const dispatch = useDispatch();
    const { data } = useParams();

    const [details, setDetails] = useState({});
    const [copied, setCopied] = useState(false);
    const [blockchain, setBlockchain] = useState("");

    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));

    useEffect(() => {
        try {
            const parsed = JSON.parse(atob(data));
            if (parsed?.id) {
                fetchUserDetails(parsed.id);
            } else {
                history.push("/auth/home");
            }
        } catch (err) {
            history.push("/auth/home");
        }
    }, []);

    const fetchUserDetails = async (id) => {
        try {
            dispatch(toggleLoading(true));

            let apiResponse = await unAuthorizedPost("", {
                query: FETCH_USER_DETAILS,
                variables: { id: id },
            });

            if (apiResponse.data.customStatus) {
                let {
                    list_user_details: { data },
                } = apiResponse.data.data;

                setDetails(data);
                setBlockchain("ethereum");
                dispatch(toggleLoading(false));
                return;
            } else {
                toast.error("Failed to fetch user details");
                dispatch(toggleLoading(false));
                return history.push("/auth/home");
            }
        } catch (err) {
            toast.error("Unable to fetch user details");
            dispatch(toggleLoading(false));
            return history.push("/auth/home");
        }
    };

    useEffect(async () => {
        updateWalletAddress();
    }, [blockchain]);

    const handleSwitch = () => {
        setBlockchain(blockchain === "ethereum" ? "solana" : "ethereum");
    };

    const updateWalletAddress = () => {
        if (details?.wallets) {
            const filtered = details.wallets.filter((e) => e.network === blockchain);
            if (filtered.length) {
                setDetails({ ...details, address: filtered[0].wallet_address });
            }
        }
    };

    return (
        <div style={{ width: "100%" }}>
            {!loading ? (
                <>
                    <Grid container justify="center" spacing={5}>
                        <Grid item lg={6} xs={11}>
                            <Box>
                                <Grid container justify="center" spacing={1}>
                                    <Grid item lg={12} xs={12}>
                                        <h3>
                                            <strong style={{ color: "#ffffff", textTransform: "capitalize" }}>
                                                {details?.name}
                                            </strong>
                                        </h3>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <span>
                                            <strong>{details?.email}</strong>
                                            <Chip
                                                // icon={<Check />}
                                                color="primary"
                                                label={<strong>verified</strong>}
                                                size="small"
                                                style={{ marginLeft: "5px" }}
                                            />
                                        </span>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        {/* <hr style={{ margin: "5px" }} /> */}
                                        <strong style={{ color: "#ffffff", textTransform: "capitalize" }}>
                                            {blockchain} Chain Wallet Address
                                        </strong>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <CustomSwitch checked={blockchain === "solana"} onChange={handleSwitch} />
                                        </div>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <AddressBar>{details?.address ?? "-"}</AddressBar>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <CopyToClipboard text={details?.address} onCopy={() => setCopied(true)}>
                                            <Button color="primary" variant="contained" style={{ width: "100%" }}>
                                                {copied ? "Copied" : "Copy Address"}
                                            </Button>
                                        </CopyToClipboard>
                                    </Grid>
                                    <Grid item>
                                        <strong>Coins accepted</strong>
                                        {blockchain === "solana" ? (
                                            <Grid container spacing={1} justify="center">
                                                {["solana"].map((e, i) => {
                                                    return (
                                                        <Grid item key={i}>
                                                            <img
                                                                style={{ width: 30, height: 30 }}
                                                                src={getBlockchainNetwork(e)}
                                                                alt=""
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        ) : (
                                            <Grid container spacing={1} justify="center">
                                                {["ethereum", "avalanche", "polygon", "usdc"].map((e, i) => {
                                                    return (
                                                        <Grid item key={i}>
                                                            <img
                                                                style={{ width: 30, height: 30 }}
                                                                src={getBlockchainNetwork(e)}
                                                                alt=""
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    {details?.address ? (
                        <>
                            <Grid container justify="center" spacing={5}>
                                <Grid item>
                                    <QrBox>
                                        <div style={{ padding: "1em" }}>
                                            <QRCode value={details?.address} />
                                        </div>
                                    </QrBox>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

export default QrHome;
