import { toast } from "react-toastify";
import { post } from "services";
import { TRANSFER_TO_EMAIL, TRANSFER_TO_WALLET } from "graphql";
import { TOGGLE_LOADING } from "store/types";

export const transferToEmail = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: TRANSFER_TO_EMAIL,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Transaction was successfull");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const transferToWallet = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: TRANSFER_TO_WALLET,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Transaction was successfull");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};
