import solana from "../assets/icons/solana.png";
import etherium from "../assets/icons/ethereum.png";
import avalanche from "../assets/icons/avalanche.png";
import polygon from "../assets/icons/polygon.png";
import usdc from "../assets/icons/usdc.png";

export const getBlockchainNetwork = (currency = "sol") => {
    if (currency === "sol" || currency === "solana") {
        return solana;
    } else if (currency === "eth" || currency === "ethereum") {
        return etherium;
    } else if (currency === "avax" || currency === "avalanche") {
        return avalanche;
    } else if (currency === "matic" || currency === "polygon") {
        return polygon;
    } else if (currency === "usdc") {
        return usdc;
    } else {
        return solana;
    }
};

export const getBlockchainCurrency = (network = "solana") => {
    if (network === "solana") {
        return "SOL";
    } else if (network === "ethereum") {
        return "ETH";
    } else if (network === "avalanche") {
        return "AVAX";
    } else if (network === "polygon") {
        return "MATIC";
    } else if (network === "usdc") {
        return "USDC";
    } else {
        return "SOL";
    }
};
