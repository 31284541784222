export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_VIEWMODE = "TOGGLE_VIEWMODE";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LIST_ALL_USER_TXNS = "LIST_ALL_USER_TXNS";
export const LIST_USER_PROFILE = "LIST_USER_PROFILE";
export const RESET_ALL_USER_TXNS = "RESET_ALL_USER_TXNS";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";

export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
