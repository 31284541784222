import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
// import SelectType from "./SelectType";
import ShareQr from "./shareQr";
// import ShowQr from "./showQr";

function QrModal({ handleModal, details }) {
    // const [type, setType] = useState("");

    return (
        <>
            <Grid item>
                <Card style={{ borderRadius: "20px", backgroundColor: "#181529", boxShadow: "0 3px 70px #000000" }}>
                    <CardContent style={{ padding: "30px 50px 20px" }}>
                        <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "white" }}>
                            <p>Send QR code via e-mail</p>
                        </div>
                        <ShareQr handleModal={handleModal} />
                        {/* {!type ? (
                            <SelectType setType={setType} />
                        ) : type === "share" ? (
                            <ShareQr network={details.network} handleModal={handleModal} />
                        ) : (
                            <ShowQr address={details.address} handleModal={handleModal} />
                        )} */}
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
}

export default QrModal;
