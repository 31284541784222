import React from "react";
import "./SwitchStyles.css";

const CustomSwitch = ({ checked = false, onChange }) => {
    return (
        <label className="switch">
            <input className="switch-input" type="checkbox" defaultChecked={checked} onChange={onChange} />
            <span className="switch-label" data-on="" data-off=""></span>
            <span className="switch-handle"></span>
        </label>
    );
};

export default CustomSwitch;
