import {
    LIST_ALL_USER_TXNS,
    RESET_ALL_USER_TXNS,
    LIST_USER_PROFILE,
    RESET_USER_PROFILE,
    UPDATE_WALLET_BALANCE,
} from "../types";

const INITIAL_STATE = {
    profile: {},
    txns: {
        list: [],
        showViewMore: false,
        pageNumber: 1,
        pageSize: 10,
    },
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_USER_PROFILE:
            return {
                ...state,
                profile: payload,
            };

        case LIST_ALL_USER_TXNS:
            return {
                ...state,
                txns: payload,
            };

        case RESET_ALL_USER_TXNS:
            return {
                ...state,
                txns: INITIAL_STATE.txns,
            };

        case RESET_USER_PROFILE:
            return {
                ...state,
                profile: INITIAL_STATE.profile,
            };

        case UPDATE_WALLET_BALANCE:
            const wallets = state.profile?.wallets ?? [];
            wallets.map((e) => {
                if (e.network === payload.network) {
                    e.balance = payload.balance;
                }
                return e;
            });
            state.profile.wallets = wallets;
            return {
                ...state,
                profile: state.profile,
            };

        default:
            return state;
    }
};

export default userReducer;
