import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ContainedButton } from "components/StyledComponents";
import "components/ExpandableListView/styles.css";

import { fetchWalletBalance } from "store/actions";

function ExpandableListView({ data, pairs, dispatch, showViewMoreButton = false, viewMoreButtonClick }) {
    const [expandBtn, setExpandBtn] = useState(0);

    const handleExpandButton = (wallet, e) => {
        if (e === expandBtn) {
            setExpandBtn(0);
        } else {
            dispatch(fetchWalletBalance({ network: wallet.network }));
            setExpandBtn(e);
        }
    };

    return (
        <>
            {data?.length ? (
                <>
                    <Grid container className="listings">
                        {data.map((e, i) => (
                            <Grid className="element" item xl={12} lg={12} xs={12} key={i}>
                                <div className="item" onClick={() => handleExpandButton(e, i + 1)}>
                                    <div className="name">
                                        {e.network} {expandBtn === i + 1 ? "" : "-" + " " + e.wallet_address}
                                    </div>
                                    <div className="dropdown-btn" title="expand">
                                        {expandBtn === i + 1 ? (
                                            <i
                                                className="fas fa-chevron-circle-down"
                                                onClick={() => handleExpandButton(e, i + 1)}
                                            />
                                        ) : (
                                            <i
                                                className="fas fa-chevron-circle-right"
                                                onClick={() => handleExpandButton(e, i + 1)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {expandBtn === i + 1 ? (
                                    <div className="expanded">
                                        {pairs.map((element, index) => (
                                            <div key={index}>
                                                <div style={{ fontWeight: 600 }}>
                                                    {" "}
                                                    {element.key} :{" "}
                                                    {element.render ? element.render(e) : e[element.value] ?? ""}{" "}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </Grid>
                        ))}
                        {showViewMoreButton ? (
                            <div style={{ display: "flex", justifyContent: "center", padding: "1em", width: "100%" }}>
                                <ContainedButton
                                    style={{ padding: "6px 10px", fontSize: "small" }}
                                    onClick={viewMoreButtonClick}
                                >
                                    View more
                                </ContainedButton>
                            </div>
                        ) : null}
                    </Grid>
                </>
            ) : (
                <div className="empty-msg">
                    <h3>No data found</h3>
                </div>
            )}
        </>
    );
}

export default ExpandableListView;
