import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Grid, Button, Modal, Divider } from "@material-ui/core";
import { resetUserProfile, listUserProfile } from "store/actions";
import ExpandableListView from "./ExpandableListView";
import { getBlockchainCurrency } from "utils";

import TransferCrypto from "../homepage/transferCrypto";
import QrModal from "./QrModal";

const Loginbox = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;

    @media screen and (max-width: 500px) {
        width: 350px;
    }
`;

const Box = styled.div`
    min-width: 150px;
    display: flex;
    align-items: center;
    background-color: rgb(16, 20, 39, 0.8);
    border-radius: 15px;
`;

function ProfilePage() {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [qrDetails, setQrDetails] = useState({});

    const { userProfile } = useSelector(({ user: { profile } }) => ({
        userProfile: profile,
    }));

    useEffect(() => {
        dispatch(resetUserProfile());
        dispatch(listUserProfile());
    }, []);

    const handleQrModal = (network) => {
        // const wallet = userProfile?.wallets ? userProfile?.wallets.filter((e) => e.network === network) : [];
        // if (wallet.length) {
        //     setQrDetails({ network: network, address: wallet[0].wallet_address });
        //     setQrModal(true);
        // }
        setQrModal(true);
    };

    return (
        <div>
            <Grid container justify="center" spacing={3}>
                <Grid item>
                    <Box style={{ justifyContent: "center" }}>
                        <Button style={{ color: "white" }} onClick={() => setIsOpen(true)}>
                            Transfer
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={12} xl={12} md={12} xs={12}>
                    <Box>
                        <div style={{ padding: "1em" }}>
                            <h3 style={{ color: "#ffffff" }}>Name: {userProfile?.name}</h3>
                            <h3 style={{ color: "#ffffff" }}>Email: {userProfile?.email}</h3>
                        </div>
                    </Box>
                </Grid>
                <Grid item lg={12} xl={12} md={12} xs={12}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <strong>Solana Chain</strong>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => handleQrModal("solana")}
                            >
                                QR code
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                    <ExpandableListView
                        data={userProfile?.wallets ? userProfile.wallets.filter((e) => e.network === "solana") : []}
                        pairs={[
                            { key: "Wallet Address", value: "wallet_address" },
                            {
                                key: "Balance",
                                render: (e) => `${e.balance ?? 0} ${getBlockchainCurrency(e.network)}`,
                            },
                        ]}
                        dispatch={dispatch}
                    />
                </Grid>
                <Grid item lg={12} xl={12} md={12} xs={12}>
                    <Grid container justify="space-between">
                        <Grid item>
                            <strong>Ethereum Chain</strong>
                        </Grid>
                        {/* <Grid item>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => handleQrModal("ethereum")}
                            >
                                QR code
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Divider />
                    <ExpandableListView
                        data={userProfile?.wallets ? userProfile.wallets.filter((e) => e.network !== "solana") : []}
                        pairs={[
                            { key: "Wallet Address", value: "wallet_address" },
                            {
                                key: "Balance",
                                render: (e) => `${e.balance ?? 0} ${getBlockchainCurrency(e.network)}`,
                            },
                        ]}
                        dispatch={dispatch}
                    />
                </Grid>
            </Grid>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <Loginbox>
                    <TransferCrypto handleModal={() => setIsOpen(false)} />
                </Loginbox>
            </Modal>
            <Modal open={qrModal} onClose={() => setQrModal(false)}>
                <Loginbox>
                    <QrModal details={qrDetails} handleModal={() => setQrModal(false)} />
                </Loginbox>
            </Modal>
        </div>
    );
}

export default ProfilePage;
