import { toast } from "react-toastify";
import { unAuthorizedPost } from "services";
import { history } from "utils";
import { SEND_OTP, LOGIN_WITH_OTP, REGISTER_NEW_USER, VERIFY_NEW_USER } from "graphql";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING, TOGGLE_MODAL } from "../types";

export const sendOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: SEND_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("OTP sent successfully");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const loginWithOtp = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: LOGIN_WITH_OTP,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                login_with_otp: { token },
            } = apiResponse.data.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: token,
            });
            history.push("/app/home");
            dispatch({ type: TOGGLE_MODAL, payload: false });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};

export const registerNewUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: REGISTER_NEW_USER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("Your OTP has been sent to your email address. Please check it out");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};

export const verifyNewUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await unAuthorizedPost("", {
            query: VERIFY_NEW_USER,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("You have successfully verified your account");
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return true;
        } else {
            dispatch({ type: TOGGLE_LOADING, payload: false });
            return false;
        }
    };
};
