import { toast } from "react-toastify";
import { post } from "services";
import { FETCH_ALL_USER_TXNS, FETCH_USER_PROFILE, FETCH_WALLET_BALANCE, SHARE_QR_LINK } from "graphql";
import {
    TOGGLE_LOADING,
    LIST_ALL_USER_TXNS,
    RESET_ALL_USER_TXNS,
    LIST_USER_PROFILE,
    RESET_USER_PROFILE,
    UPDATE_WALLET_BALANCE,
} from "store/types";

export const listAllUserTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const {
            txns: { pageSize, pageNumber, list },
        } = getState().user;

        let apiResponse = await post("", {
            query: FETCH_ALL_USER_TXNS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                ...requestData,
            },
        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_transactions: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_ALL_USER_TXNS,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllUserTxns = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_ALL_USER_TXNS });
    };
};

export const listUserProfile = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_USER_PROFILE,
        });

        if (apiResponse.data.customStatus) {
            let {
                user_profile: { data },
            } = apiResponse.data.data;

            dispatch({
                type: LIST_USER_PROFILE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetUserProfile = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USER_PROFILE });
    };
};

export const fetchWalletBalance = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: FETCH_WALLET_BALANCE,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_wallet_balance: { data },
            } = apiResponse.data.data;

            dispatch({
                type: UPDATE_WALLET_BALANCE,
                payload: data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const shareQrLink = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        let apiResponse = await post("", {
            query: SHARE_QR_LINK,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            toast.success("QR link shared");
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};
