import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FilledInput, InputAdornment, CircularProgress as Loader } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Lock } from "@material-ui/icons";

import { verifyNewUser } from "store/actions";

function VerifyUser({ email, handlePage }) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector(({ loading }) => ({
        isLoading: loading,
    }));

    return (
        <>
            <Formik
                initialValues={{
                    otp: "",
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().max(255).required("otp is required"),
                })}
                onSubmit={async (value) => {
                    value.email = email.email;
                    const response = await dispatch(verifyNewUser(value));
                    if (response) {
                        handlePage();
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FilledInput
                            autoComplete="off"
                            type="text"
                            fullWidth
                            placeholder="Enter otp"
                            name="otp"
                            defaultValue={values.otp}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            }
                            required={true}
                            error={Boolean(touched.otp && errors.otp)}
                        />
                        {Boolean(touched.otp && errors.otp) ? (
                            <div
                                style={{
                                    display: "block",
                                    marginLeft: "10px",
                                    color: "red",
                                    fontSize: 13,
                                }}
                            >
                                {errors.otp}
                            </div>
                        ) : (
                            ""
                        )}
                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                            <Button id="signin-btn" color="primary" variant="contained" onClick={handleSubmit}>
                                {isLoading ? <Loader color="secondary" size={20} /> : "Verify Account"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default VerifyUser;
