import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Grid } from "@material-ui/core";

import ExpandableListView from "components/ExpandableListView/ExpandableListView";

import { EmptyBox } from "components/StyledComponents";
import { resetAllUserTxns, listAllUserTxns } from "store/actions";

function Transactions() {
    const dispatch = useDispatch();

    const { txnsData, showViewMore } = useSelector(({ user: { txns } }) => ({
        txnsData: txns.list,
        showViewMore: txns.showViewMore,
    }));

    useEffect(() => {
        dispatch(resetAllUserTxns());
        fetchUserTxns();
    }, []);

    const fetchUserTxns = () => {
        dispatch(listAllUserTxns());
    };

    return (
        <div>
            <Grid container spacing={3}>
                {txnsData ? (
                    <Grid item lg={12} xl={12} md={12}>
                        <ExpandableListView
                            pairs={[
                                { key: "TXN ID", value: "txn_id" },
                                {
                                    key: "Status",
                                    value: "status",
                                    // colorIndication: { value: "signal", condition: "BUY"}
                                },
                                {
                                    key: "Amount",
                                    render: (rowData) => `${rowData?.amount} ${rowData?.currency.toUpperCase()}`,
                                },
                                { key: "Network", value: "chain_network" },
                                { key: "Type", value: "type" },
                                // { key: "TXN Hash", value: "txn_signature" },
                                {
                                    key: "Created At",
                                    render: (rowData) => moment.unix(rowData?.created_at).format("DD-MM-YYYY hh:mm A"),
                                },
                                { key: "User", render: (rowData) => (rowData?.to_uid ? rowData.to_uid?.email : "-") },
                                { key: "User Wallet", render: (rowData) => rowData?.to_user_wid ?? "-" },
                            ]}
                            data={txnsData}
                            showViewMoreButton={showViewMore}
                            viewMoreButtonClick={fetchUserTxns}
                        />
                    </Grid>
                ) : (
                    <EmptyBox>No data found</EmptyBox>
                )}
            </Grid>
        </div>
    );
}

export default Transactions;
